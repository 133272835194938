import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import ClipLoader from "react-spinners/ClipLoader";
import QRCode from "react-qr-code";
import "../App.css";
import bgImage from "../svg/waves.svg";
import email from "../svg/mail.svg";
import call from "../svg/call.svg";
import linkedin from "../svg/linkedin.svg";
import location from "../svg/location.svg";
import website from "../svg/website.svg";
import businesscard from "../svg/businesscard.svg";
import logoPlaceholder from "../svg/logo-placeholder.svg";
import userPlaceholder from "../svg/user-placeholder.svg";
//const apiUrl = "https://rmb.cognisun.net:5681/"; // PROD
// const apiUrl = 'https://rmb.cognisunsandbox.com:4014/';       // UAT
// router
const apiUrl = "https://businesspositive.cognisun.net:4054/";
//const apiUrl = 'http://localhost:4052/'  // Local

export const Users = () => {
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [profilePicture, setProfilePicture] = useState([]);
  const [companyLogo, setCompanyLogo] = useState([]);
  const [qrCodeData, setQrCodeData] = useState(null);
  // const params = useParams();
  // let personid = params.userId;

  // console.log("queryParams",personid);
  useEffect(() => {
    getMemberDetail();
    generateVCard();
  }, []);

  const getMemberDetail = () => {
    fetch(`${apiUrl}member/getMemberDetails`, {
      method: "POST",
      body: JSON.stringify({
        personid: window.location.pathname.split("/").slice(-1)[0],
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.data[0]);
        setProfilePicture(apiUrl + res.data[0].profilepicture);
        setCompanyLogo(apiUrl + res.data[0].logo);
        setTimeout(() => {
          setSpinner(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  // const handleClick = () => {
  // 	if (navigator.share) {
  // 	  navigator.share({
  // 		title: 'QR Code',
  // 		text: 'Check out this QR code',
  // 		url: 'https://www.example.com'
  // 	  })
  // 		.then(() => console.log('Sharing was successful.'))
  // 		.catch((error) => console.error('Sharing failed:', error));
  // 	} else {
  // 	  console.error('Web Share API is not supported by this browser.');
  // 	}
  //   };
  const generateVCard = () => {
    const vcard = [
      "BEGIN:VCARD",
      "VERSION:4.0",
      `FN:${data.firstname} ${data.lastname}`,
      `TEL;TYPE=WORK,VOICE:${"+" + data.countrycode + data.phonenum}`,
      `EMAIL;TYPE=PREF,INTERNET:${data.email}`,
      `ORG:${data.businessname}`,
      `URL;TYPE=LINKEDIN${data.linkdlnlink}`,
      `URL;TYPE=WEBSITE${data.website}`,
      `URL;TYPE=WEBSITE${data.website}`,
      "END:VCARD",
    ].join("\n");

    const blob = new Blob([vcard], { type: "text/vcard;charset=utf-8" });
    const vCardURL = URL.createObjectURL(blob);
    setQrCodeData(vCardURL);
    // saveAs(blob, `${data.firstname}_${data.lastname}.vcf`);
  };
  const generateWebURL = (url) => {
    if (!url.startsWith("http")) {
      window.open(`http://${url}`, "_blank");
    } else if (url.startsWith("https")) {
      window.open(`${url}`, "_blank");
    } else if (url.startsWith("http:")) {
      window.open(`${url}`, "_blank");
    } else {
      window.open(`https://${url}`, "_blank");
    }
  };

  return spinner ? (
    <div className="spinner-wrap">
      <ClipLoader color="#3366FF" size={90} border-width={5} />
    </div>
  ) : (
    <div className="App">
      <div className="main-wrap">
        <div className="innercard-wrap">
          <div className="person-image">
            <img
              src={data.profilepicture ? profilePicture : userPlaceholder}
              alt="Loading Image"
            />
            <div className="waves-bg">
              <img src={bgImage} alt="BG"></img>
              <div className="logo-image">
                <img
                  src={data.logo ? companyLogo : logoPlaceholder}
                  alt="Loading Image"
                />
              </div>
            </div>
          </div>
          <div className="person-details">
            <div className="person-details-inner">
              <h3>{data.firstname + " " + data.lastname} </h3>
              <p className="Cname">{data.occupation}</p>
              <p className="Cname">{data.businessname}</p>
            </div>
          </div>

          <div className="person-contacts">
            {data.email ? (
              <button
                classname="email"
                onClick={() => {
                  window.open(
                    `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${data.email}`,
                    "_blank"
                  );
                }}
              >
                <img src={email} alt="Email"></img>
                <span>{data.email}</span>
              </button>
            ) : null}
            {data.phonenum ? (
              <button
                className="call"
                onClick={() => {
                  window.location.href = `tel:${data.phonenum}`;
                }}
              >
                <img src={call} alt="Phone"></img>
                <span>
                  {"+" +
                    data.countrycode +
                    " " +
                    [
                      data.phonenum.slice(0, 5),
                      " ",
                      data.phonenum.slice(5),
                    ].join("")}
                </span>
              </button>
            ) : null}
            {data.fullAddress ? (
              <button
                className="address"
                onClick={() => {
                  window.open(
                    `https://maps.google.com?q=${data.fullAddress}`,
                    "_blank"
                  );
                }}
              >
                <img src={location} alt="Location"></img>
                <span>{data.fullAddress}</span>
              </button>
            ) : null}
            {data.linkdlnlink ? (
              <button
                className="linkedin"
                onClick={() => {
                  window.open(`${data.linkdlnlink}`, "_blank");
                }}
              >
                <img src={linkedin} alt="Linkedin"></img>
                <span>{data.linkdlnlink}</span>
              </button>
            ) : null}
            {data.website ? (
              <button
                className="website"
                onClick={() => {
                  generateWebURL(data.website);
                }}
              >
                <img src={website} alt="Website"></img>
                <span>{data.website}</span>
              </button>
            ) : null}
          </div>
          <center>
            {/* <QRCode  onScan={generateVCard} /> */}
            {/* <QRCode value="https://www.example.com" /> */}
            <QRCode
              style={{
                width: 220,
                height: 220,
                marginTop: 30,
                marginBottom: 30,
              }}
              value={`BEGIN:VCARD\nVERSION:4.0\nFN:${data.firstname} ${
                data.lastname
              }\nTEL;TYPE=WORK,VOICE:${
                "+" + data.countrycode + data.phonenum
              }\nEMAIL;TYPE=PREF,INTERNET:${data.email}\nORG:${
                data.businessname
              }\nTITLE:${data.occupation}\nURL;TYPE=LINKEDIN:${
                data.linkdlnlink
              }\nURL;TYPE=WEBSITE:${data.website}\nEND:VCARD`}
            />
          </center>
          {/* <button className='save-contact-btn' onClick={qrCodeData}>
				<img src={businesscard}></img>
				<span>SHARE QR CODE</span>
			</button> */}
          {/* <TextareaAutosize
                style={{fontSize:18, width:320, height:100, marginTop:100}}
                rowsMax={4}
                defaultValue={qrscan}
                value={qrscan}
            /> */}
        </div>
        {/* <button className='save-contact-btn' onClick={() => { generateVCard() }}>
				<img src={businesscard}></img>
				<span>SAVE CONTACT</span>
			</button> */}
      </div>
    </div>
  );
};
