import React from 'react';
import { Route, Link, Routes, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { HomePage } from './pages/Homepage.js';
import { Users } from './pages/Userpage.js';


export default function App() {
  return (
    <div>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/users/:userId" element={<Users />} /> */}
          <Route path="/:userId" element={<Users />} />
        </Routes>
      </div>
    </div>
  );
}
